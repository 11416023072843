<template>
  <div class="port">
    <div class="port-info">
      <div class="port-info__create">
        <base-button
          :disabled="isPorts === 0"
          class="port-info__create-btn"
          @click="newPort(tariff)"
        >
          {{ $t('newPort') }}
        </base-button>
        <plain-button
          v-if="isPorts === 0"
          v-tooltip="{
            content: $t('quotaPorts'),
            placement: 'top-start',
            container: false,
          }"
          icon="warn"
          size="medium"
          color="del"
          tabindex="-1"
          class="port-info__create-hint"
        />
      </div>
    </div>
    <transition name="bubble">
      <page-block class="table">
        <tariffs-table-port :dataset="ports" />
      </page-block>
    </transition>
  </div>
</template>

<script>
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import TariffsTablePort from '@/layouts/Stack/pages/Main/components/TariffsTablePort';
import newPort from '@/mixins/newPort';

export default {
  name: 'PortView',
  components: {
    TariffsTablePort,
  },
  mixins: [providerChange, newPort],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  computed: {
    networkId() {
      return this.tariff.id;
    },
    isPorts() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.port
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.port.limit -
          this.$store.state.moduleStack.quotasNetwork.port.used
        );
      } else return 0;
    },
    floatingIp() {
      return this.$store.state.moduleStack.floatingips.filter(x => x.port_id === null);
    },
    ports() {
      return this.$store.getters['moduleStack/GET_SORTED_LIST_PORTS'].filter(
        x => x.network_id === this.networkId
      );
      // return ports;
    },
  },
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "attach": "Подключен",
      "size": "Размер",
      "type": "Тип диска",
      "os": "Источник",
      "name": "Тип конфигурации",
      "cpu": "vCPU",
      "ram": "Память",
      "newPort": "Создать порт",
      "quotaPort": "Вы достигли квоты по количеству портов, обратитесь в поддержку для изменения квоты",
      "sure": {
        "confirm": "Создать"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.table {
  margin-top: 0.5rem;
}
.port {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__create {
      display: flex;
      flex-direction row;
      align-content flex-end;
      align-items baseline;
      margin-top: 1.5rem;
      max-width: 200px;
      &-btn {
        margin-top: 20px
        margin-right: 0;
        +breakpoint(ms-and-up) {
          margin-top: 20px;
        }
      }
      &-hint {
        margin: -0.25rem 0.25rem;
        vertical-align: middle;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

}
</style>
